import { ReactNode, useCallback } from "react";
import { Trans } from "@lingui/macro";
import {
  Visibility,
  Banner,
  BannerStatus,
  BannerVariant,
  // eslint-disable-next-line no-restricted-imports
  useToast,
  Box,
} from "@gocardless/flux-react";

interface NotificationProps {
  message: ReactNode;
  title: ReactNode;
  status: BannerStatus;
  id?: string;
  closeLabel?: string;
  hideOnMobile?: boolean;
  duration?: number;
  showUnique?: boolean;
}

export interface NotificationPayload {
  message: ReactNode;
  id?: string;
  title?: ReactNode;
  closeLabel?: string;
  hideOnMobile?: boolean;
  duration?: number;
  showUnique?: boolean;
}

const DEFAULT_TOAST_DURATION = 4000; // Taken from Flux docs

export const useToastNotification = () => {
  const { toast, dismissToast, dismissAllToasts } = useToast();

  const notification = useCallback(
    ({
      title,
      status,
      message,
      closeLabel = "Close",
      id = "toastNotification",
      hideOnMobile = false,
      duration = DEFAULT_TOAST_DURATION,
      showUnique,
    }: NotificationProps) => {
      if (showUnique) dismissAllToasts();
      toast(
        (t) => (
          <Box spaceBefore={2}>
            <Visibility visible={[hideOnMobile ? "none" : "block", "block"]}>
              <Banner
                variant={BannerVariant.Light}
                status={status}
                closeAction={{
                  onClose: () => dismissToast(t.id),
                  label: closeLabel,
                }}
                id={id}
                title={title}
              >
                {message}
              </Banner>
            </Visibility>
          </Box>
        ),
        { duration }
      );
    },
    [dismissToast, toast, dismissAllToasts]
  );

  const triggerErrorNotification = useCallback(
    (payload: NotificationPayload) => {
      const DEFAULT_TITLE = (
        <Trans id="notifications.error.title">There was an error</Trans>
      );

      notification({
        title: DEFAULT_TITLE,
        status: BannerStatus.Warning,
        ...payload,
      });
    },
    [notification]
  );

  const triggerSuccessNotification = useCallback(
    (payload: NotificationPayload) => {
      const DEFAULT_TITLE = (
        <Trans id="notifications.success.title">Success</Trans>
      );

      notification({
        title: DEFAULT_TITLE,
        status: BannerStatus.Success,
        ...payload,
      });
    },
    [notification]
  );

  const triggerInfoNotification = useCallback(
    (payload: NotificationPayload) => {
      const DEFAULT_TITLE = <Trans id="notifications.info.title">Info</Trans>;

      notification({
        title: DEFAULT_TITLE,
        status: BannerStatus.Info,
        ...payload,
      });
    },
    [notification]
  );

  const triggerWarningNotification = useCallback(
    (payload: NotificationPayload) => {
      const DEFAULT_TITLE = (
        <Trans id="notifications.warning.title">Warning</Trans>
      );

      notification({
        title: DEFAULT_TITLE,
        status: BannerStatus.Warning,
        ...payload,
      });
    },
    [notification]
  );

  return {
    triggerInfoNotification,
    triggerErrorNotification,
    triggerSuccessNotification,
    triggerWarningNotification,
  };
};
