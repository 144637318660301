import {
  Box,
  BoxProps,
  Button,
  ButtonSize,
  ButtonVariant,
  JustifyContent,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
export interface ExportCentreFooterProps extends BoxProps {
  dismiss: Function;
}

const ExportsCentreFooter: React.FC<ExportCentreFooterProps> = (props) => {
  const { dismiss } = props;

  return (
    <Box
      gutterV={1}
      gutterH={1}
      justifyContent={JustifyContent.FlexEnd}
      layout="flex"
    >
      <Button
        size={ButtonSize.Sm}
        variant={ButtonVariant.PrimaryOnLight}
        data-testid="dismiss"
        onClick={() => dismiss()}
      >
        <Trans id="Dismiss">Dismiss</Trans>
      </Button>
    </Box>
  );
};

export default ExportsCentreFooter;
