import { useUserShowSelf } from "@gocardless/api/dashboard/user";
import { useEffect } from "react";

import { UserLocale } from "../../common/i18n";

import { useI18n } from ".";

export interface UserLocaleWrapperProps {
  children: React.ReactNode;
}

const UserLocaleWrapper: React.FC<UserLocaleWrapperProps> = (props) => {
  const { data } = useUserShowSelf();
  const [, updateLocale] = useI18n();

  useEffect(() => {
    if (data?.users?.locale) {
      updateLocale(data?.users?.locale as UserLocale);
    }
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.users?.locale]);

  return <>{props.children}</>;
};

export default UserLocaleWrapper;
