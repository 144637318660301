import { createContext, useContext } from "react";
import { useUserShowSelf } from "@gocardless/api/dashboard/user";

import {
  DEFAULT_LOCALE,
  LanguageCode,
  LanguageNameToCode,
  UserLocale,
  APILocale,
  Languages,
  LocaleNames,
  ShorthandLocale,
} from "./locales";

export {
  UserLocale,
  APILocale,
  LanguageCode,
  Languages,
  LocaleNames,
  ShorthandLocale,
  DEFAULT_LOCALE,
};

export interface AlternativeLangs {
  [locale: string]: string | undefined;
}

export interface LocaleContextValue {
  locale: string;
  alternativeLangs: AlternativeLangs;
}

export const LocaleContext = createContext<LocaleContextValue>({
  locale: DEFAULT_LOCALE,
  alternativeLangs: {},
});

type I18nReturnState = [UserLocale, (locale: UserLocale) => void];

export const I18nContext = createContext<I18nReturnState>([
  DEFAULT_LOCALE,
  () => DEFAULT_LOCALE,
]);

export const useI18n = (): I18nReturnState => useContext(I18nContext);

export const DEFAULT_LANGUAGE = LanguageCode.EN;

export const useUserLanguage = (): LanguageCode => {
  const userData = useUserShowSelf();
  const language = userData?.data?.users?.language || DEFAULT_LANGUAGE;
  return LanguageNameToCode[language] || LanguageCode.EN;
};
