import { GC_SUPPORT_ORIGIN } from "src/common/constants/zendesk";
import { Route, getRoute } from "src/common/routing";

/**
 * A class that extends the native URL interface and provides methods for manipulating Zendesk URLs.
 */
export class ZendeskUrl extends URL {
  constructor(formId: string, locale?: string) {
    super(ZendeskUrl.getLocalizedOrDefaultPathname(locale), GC_SUPPORT_ORIGIN);
    this.searchParams.set("ticket_form_id", formId);
  }

  /**
   * Retrieves the localized or default pathname based on the provided locale.
   *
   * @param {string} locale - the locale to be used for localization
   * @return {string} the localized or default pathname
   */
  static getLocalizedOrDefaultPathname(locale = ""): string {
    let maybeLocale = "";

    try {
      new Intl.Locale(locale);
      maybeLocale = locale?.toLowerCase();
    } catch {
      // ignore
    }

    return ["hc", maybeLocale, "requests", "new"].filter(Boolean).join("/");
  }

  /**
   * Sets the URL search parameters to the URL.
   *
   * @param {URLSearchParams} params - the parameters to set to the URL
   * @return {ZendeskUrl} the current ZendeskUrl instance
   */
  withSearchParams(params: URLSearchParams): ZendeskUrl {
    params.forEach((value, key) => {
      this.searchParams.set(key, value);
    });

    return this;
  }

  /**
   * Sets `tf_anonymous_requester_email` in the URL search parameters.
   *
   * @param {string} email - the email to set
   * @return {ZendeskUrl} the current ZendeskUrl instance
   */
  withEmail(email = ""): ZendeskUrl {
    if (email.length) {
      this.searchParams.set("tf_anonymous_requester_email", email);
    }
    return this;
  }
}

/**
 * Determines if the given pathName corresponds to the Zendesk Forms Interceptor route.
 * It extracts the form ID from the pathName and compares the resolved route for that ID
 * with the provided pathName to check for a match.
 *
 * @param {string} pathName - The path name to check against the Zendesk Forms Interceptor route.
 * @returns {boolean}
 */
export const isZendeskFormsInterceptorRoute = (pathName: string) => {
  /**
   * Note: Consider refactoring the pathName matching logic to use the URLPattern API
   * once it is implemented by all major browsers for more robust pattern matching.
   *
   * @see https://urlpattern.spec.whatwg.org/#urlpattern - URLPattern API specification.
   * @see https://caniuse.com/?search=urlpattern - Browser support for URLPattern API.
   */
  const formId = pathName.split("/").pop();

  if (!formId?.length) {
    return false;
  }

  const resolvedRoute = getRoute(Route.ZendeskFormsInterceptor, { formId });

  return resolvedRoute === pathName;
};
