// useLoadDrift.tsx
import { useState, useEffect } from "react";
import { getConfig } from "src/common/config";
import { loadDriftScript } from "src/vendor/drift";
import {
  AccessTokenCreateRequestBodyScope,
  UserResource,
} from "@gocardless/api/dashboard/types";
import { useTranscendConsent } from "src/components/third-parties/Transcend";

export const useLoadDrift = (user: UserResource | undefined) => {
  const [isDriftLoaded, setIsDriftLoaded] = useState(false);
  const writeKey = getConfig().client.segment.key;
  const driftAppId = getConfig().client.drift.appId;
  const { consent } = useTranscendConsent();
  const trackingAllowed = !!consent.Analytics;
  const isSafeToLoad =
    window && user?.scope === AccessTokenCreateRequestBodyScope.Admin;

  useEffect(() => {
    if (isSafeToLoad && !window.drift && driftAppId) {
      if (trackingAllowed) {
        loadDriftScript(writeKey);
      } else {
        loadDriftScript("");
      }
      setIsDriftLoaded(true);
    }
  }, [isSafeToLoad, trackingAllowed, writeKey, driftAppId]);

  return { isDriftLoaded };
};
