import { compile, PathFunction, pathToRegexp } from "path-to-regexp";

export enum Route {
  AboutYou = "AboutYou",
  AccountStatus = "AccountStatus",
  AccountClosure = "AccountClosure",
  AchUpliftDetails = "AchUpliftDetails",
  Admin = "Admin",
  AppAccessTokens = "AppAccessTokens",
  BankAccountData = "BankAccountData",
  BankDetails = "BankDetails",
  BankDetailsSelect = "BankDetailsSelect",
  BankStatementName = "BankStatementName",
  BillingDetails = "BillingDetails",
  BillingDetailsCallback = "BillingDetailsCallback",
  Blocklist = "Blocklist",
  BlocklistCreateBlock = "BlocklistCreateBlock",
  BusinessCategory = "BusinessCategory",
  BusinessDetails = "BusinessDetails",
  BusinessDirectors = "BusinessDirectors",
  BusinessOwners = "BusinessOwners",
  Brand = "Brand",
  BrandLogoAndColours = "BrandLogoAndColours",
  BrandContactDetails = "BrandContactDetails",
  BrandBankReference = "BrandBankReference",
  BrandCustomPaymentExperience = "BrandCustomPaymentExperience",
  Chargebacks = "Chargebacks",
  ChargebacksSelectRelatedChargebacks = "ChargebacksSelectRelatedChargebacks",
  ChargebackUploadEvidence = "ChargebackUploadEvidence",
  CompanySettings = "CompanySettings",
  CompanyPlanAndAddons = "CompanyPlanAndAddons",
  ConfigurationPresets = "ConfigurationPresets",
  CompanyConfigurations = "CompanyConfigurations",
  ConnectedApps = "ConnectedApps",
  ContactDetails = "ContactDetails",
  CreateAccount = "CreateAccount",
  CreatePaymentProviderAccount = "CreatePaymentProviderAccount",
  CreditorBankAccounts = "CreditorBankAccounts",
  CreditorDetails = "CreditorDetails",
  Creditors = "Creditors",
  Customer = "Customer",
  CustomerBankAccount = "CustomerBankAccount",
  CustomerBankAccounts = "CustomerBankAccounts",
  Customers = "Customers",
  CustomersCreate = "CustomersCreate",
  CustomerView = "CustomerView",
  CustomBankStatement = "CustomBankStatement",
  CustomBankStatementMandate = "CustomBankStatementMandate",
  CustomBankStatementMandateCallback = "CustomBankStatementMandateCallback",
  Developers = "Developers",
  DevelopersAccessTokenEdit = "Developers Access Token Edit",
  DevelopersPartnersFees = "Developers Partner Fees",
  Events = "Events",
  ForcedPasswordReset = "ForcedPasswordReset",
  ForgotPassword = "ForgotPassword",
  GetStarted = "GetStarted",
  Home = "Home",
  ImportBulkCustomers = "ImportBulkCustomers",
  ImportBulkMandatesBulkChangeAch = "ImportBulkMandatesBulkChangeAch",
  ImportBulkMandatesBulkChangeAutogiro = "ImportBulkMandatesBulkChangeAutogiro",
  ImportBulkMandatesBulkChangeBacs = "ImportBulkMandatesBulkChangeBacs",
  ImportBulkMandatesBulkChangeBecs = "ImportBulkMandatesBulkChangeBecs",
  ImportBulkMandatesBulkChangeBecsNz = "ImportBulkMandatesBulkChangeBecsNz",
  ImportBulkMandatesBulkChangeBetalingsservice = "ImportBulkMandatesBulkChangeBetalingsservice",
  ImportBulkMandatesBulkChangePad = "ImportBulkMandatesBulkChangePad",
  ImportBulkMandatesBulkChangeSepa = "ImportBulkMandatesBulkChangeSepa",
  ImportBulkMandatesMandateMigrationPayTo = "ImportBulkMandatesMandateMigrationPayTo",
  ImportBulkPayments = "ImportBulkPayments",
  Import = "Import",
  Imports = "Imports",
  ImportsPreview = "ImportsPreview",
  ImportsBulkChangeEntry = "ImportsBulkChangeEntry",
  ImportsBulkChangeFlow = "ImportsBulkChangeFlow",
  ImportsBulkChangeRequest = "ImportsBulkChangeRequest",
  InstalmentSchedule = "InstalmentSchedule",
  InstalmentScheduleCreate = "InstalmentScheduleCreate",
  InstalmentScheduleCreateSuccess = "InstalmentScheduleCreateSuccess",
  InstalmentScheduleCustomerSearch = "InstalmentScheduleCustomerSearch",
  InstalmentScheduleSelection = "InstalmentScheduleSelection",
  InstalmentSchedules = "InstalmentSchedules",
  IntelligentRetries = "IntelligentRetries",
  Intro = "Intro",
  LogoUpload = "LogoUpload",
  Mandate = "Mandate",
  Mandates = "Mandates",
  MFASetup = "MFASetup",
  MFASetupAuthenticator = "MFASetupAuthenticator",
  MFASetupSMS = "MFASetupSMS",
  Monitoring = "Monitoring",
  MoreBusinessDetails = "MoreBusinessDetails",
  NotFound = "NotFound",
  Onboarding = "Onboarding",
  OnboardingPackageSelection = "OnboardingPackageSelection",
  OnboardingWelcome = "OnboardingWelcome",
  OnboardingWelcomeConnect = "OnboardingWelcomeConnect",
  OneOffPaymentCreate = "OneOffPaymentCreate",
  OneOffPaymentCreateSuccess = "OneOffPaymentCreateSuccess",
  OneOffPaymentCustomerSearch = "OneOffPaymentCustomerSearch",
  OneOffPaymentPaylinksList = "OneOffPaymentPaylinksList",
  OneOffPaymentSelection = "OneOffPaymentSelection",
  PackageSelection = "PackageSelection",
  PackageSelectionEdit = "PackageSelectionEdit",
  PasswordEdit = "PasswordEdit",
  PaylinksCreate = "PaylinksCreate",
  PaylinkShare = "PaylinkShare",
  Payment = "Payment",
  PaymentCreate = "PaymentCreate",
  PaymentName = "PaymentName",
  PaymentRequest = "PaymentRequest",
  PaymentRequestEmail = "PaymentRequestEmail",
  PaymentRequestLink = "PaymentRequestLink",
  Payments = "Payments",
  PaymentSetting = "PaymentSetting",
  PaymentShare = "PaymentShare",
  PaymentVolumes = "PaymentVolumes",
  Payout = "Payout",
  Payouts = "Payouts",
  PayoutTransactions = "PayoutTransactions",
  PersonActingOnBehalfOf = "PersonActingOnBehalfOf",
  PublicKey = "PublicKey",
  RecurringPaymentCreate = "RecurringPaymentCreate",
  RecurringPaymentCreateSuccess = "RecurringPaymentCreateSuccess",
  RecurringPaymentCustomerSearch = "RecurringPaymentCustomerSearch",
  RecurringPaymentSelection = "RecurringPaymentSelection",
  Refer = "Refer",
  ReferralLink = "ReferralLink",
  Refund = "Refund",
  Refunds = "Refunds",
  RequestPayments = "RequestPayments",
  RiskSettings = "RiskSettings",
  RiskSettingsChange = "RiskSettingsChange",
  RouteTo = "RouteTo",
  Search = "Search",
  Settings = "Settings",
  Setup = "Setup",
  SetupPayments = "SetupPayments",
  SetupUser = "SetupUser",
  SetupUserVerify = "SetupUserVerify",
  SignIn = "SignIn",
  SignUp = "SignUp",
  SignUpExp = "SignUpExp",
  Subscription = "Subscription",
  SubscriptionPause = "SubscriptionPause",
  SubscriptionResume = "SubscriptionResume",
  Subscriptions = "Subscriptions",
  SubscriptionTemplate = "SubscriptionTemplate",
  SubscriptionTemplateCustomers = "SubscriptionTemplateCustomers",
  SubscriptionTemplateEdit = "SubscriptionTemplateEdit",
  SubscriptionTemplates = "SubscriptionTemplates",
  SubscriptionTemplatesCreate = "SubscriptionTemplatesCreate",
  SubscriptionTemplatesCustomerSearch = "SubscriptionTemplatesCustomerSearch",
  SubscriptionTemplateShare = "SubscriptionTemplateShare",
  SubscriptionTemplateShareAssignCustomers = "SubscriptionTemplateShareAssignCustomers",
  SubscriptionTemplatesSelection = "SubscriptionTemplatesSelection",
  SubscriptionTemplateManagePaymentSchedule = "SubscriptionTemplateManagePaymentSchedule",
  SubscriptionTemplateSchedulePause = "SubscriptionTemplateSchedulePause",
  SubscriptionTemplateScheduleResume = "SubscriptionTemplateScheduleResume",
  SuccessPlus = "SuccessPlus",
  SuccessPlusConfigure = "SuccessPlusConfigure",
  SuccessPlusReporting = "SuccessPlusReporting",
  Team = "Team",
  TeamUserEdit = "TeamUserEdit",
  TrusteeDetails = "TrusteeDetails",
  TrustOwners = "TrustOwners",
  PaymentProviderBranding = "PaymentProviderBranding",
  VerificationBanner = "VerificationBanner",
  Verified = "Verified",
  VerifyBank = "VerifyBank",
  VerifyBankDocument = "VerifyBankDocument",
  Welcome = "Welcome",
  WelcomePaymentType = "WelcomePaymentType",
  WelcomeUsageSelection = "WelcomeUsageSelection",
  ZendeskFormsInterceptor = "ZendeskFormsInterceptor",
}

const routes: Record<Route, PathFunction> = {
  [Route.AboutYou]: compile("/setup/about-you"),
  [Route.AccountStatus]: compile("/setup/account-status"),
  [Route.AccountClosure]: compile("/company/settings/close"),
  [Route.AchUpliftDetails]: compile("/setup/ach-uplift-details"),
  [Route.Admin]: compile("/admin"),
  [Route.AppAccessTokens]: compile("/app-access-tokens"),
  [Route.BankAccountData]: compile("/bank-account-data"),
  [Route.BankDetails]: compile("/setup/bank-details"),
  [Route.BankDetailsSelect]: compile("/setup/bank-details-select"),
  [Route.BankStatementName]: compile("/setup/bank-statement-name"),
  [Route.BillingDetails]: compile("/setup/billing-details"),
  [Route.BillingDetailsCallback]: compile("/setup/billing-details/callback"),
  [Route.Blocklist]: compile("/protect-plus/blocklist"),
  [Route.BlocklistCreateBlock]: compile("/protect-plus/blocklist/create-block"),
  [Route.BusinessCategory]: compile("/setup/business-category"),
  [Route.BusinessDetails]: compile("/setup/business-details"),
  [Route.BusinessDirectors]: compile("/setup/business-directors"),
  [Route.BusinessOwners]: compile("/setup/business-owners"),
  [Route.Brand]: compile("/brand"),
  [Route.BrandLogoAndColours]: compile("/brand/logo-and-colours"),
  [Route.BrandContactDetails]: compile("/brand/contact-details"),
  [Route.BrandBankReference]: compile("/brand/bank-reference"),
  [Route.BrandCustomPaymentExperience]: compile(
    "/brand/custom-payment-experience"
  ),
  [Route.Chargebacks]: compile("/chargebacks"),
  [Route.ChargebacksSelectRelatedChargebacks]: compile(
    "/chargebacks/:id/select-related-chargebacks"
  ),
  [Route.ChargebackUploadEvidence]: compile("/chargebacks/:id/upload-evidence"),
  [Route.CompanyConfigurations]: compile("/company/configurations"),
  [Route.CompanySettings]: compile("/company/settings"),
  [Route.CompanyPlanAndAddons]: compile("/company/plan-and-addons"),
  [Route.ConfigurationPresets]: compile("/success-plus/configuration-presets"),
  [Route.ConnectedApps]: compile("/company/apps"),
  [Route.ContactDetails]: compile("/setup/contact-details"),
  [Route.CreateAccount]: compile("/create-account"),
  [Route.CreatePaymentProviderAccount]: compile("/embed/create-account"),
  [Route.CreditorBankAccounts]: compile("/creditor-bank-accounts/:id"),
  [Route.CreditorDetails]: compile("/creditors/:id"),
  [Route.Creditors]: compile("/creditors"),
  [Route.Customer]: compile("/customers/:id"),

  [Route.CustomerBankAccount]: compile("/customer-bank-accounts/:id"),
  [Route.CustomerBankAccounts]: compile("/customer-bank-accounts"),
  [Route.Customers]: compile("/customers"),
  [Route.CustomersCreate]: compile("/customers/create/:id"),
  [Route.CustomerView]: compile("/customers/:id"),
  [Route.CustomBankStatement]: compile("/custom-bank-statement/business-name"),
  [Route.CustomBankStatementMandate]: compile("/custom-bank-statement/mandate"),
  [Route.CustomBankStatementMandateCallback]: compile(
    "/custom-bank-statement/mandate-callback"
  ),
  [Route.Developers]: compile("/developers"),
  [Route.DevelopersAccessTokenEdit]: compile(
    "/developers/access-tokens/:id/edit"
  ),
  [Route.DevelopersPartnersFees]: compile("/developers/partners/fees"),
  [Route.Events]: compile("/events"),
  [Route.ForcedPasswordReset]: compile("/password/forced-reset"),
  [Route.ForgotPassword]: compile("/password/create"),
  [Route.GetStarted]: compile("/get-started"),
  [Route.Home]: compile("/"),
  [Route.ImportBulkCustomers]: compile("/imports/create/customers"),
  [Route.ImportBulkMandatesBulkChangeAch]: compile(
    "/imports/create/mandates/bulk_change_ach"
  ),
  [Route.ImportBulkMandatesBulkChangeAutogiro]: compile(
    "/imports/create/mandates/bulk_change_autogiro"
  ),
  [Route.ImportBulkMandatesBulkChangeBacs]: compile(
    "/imports/create/mandates/bulk_change_bacs"
  ),
  [Route.ImportBulkMandatesBulkChangeBecs]: compile(
    "/imports/create/mandates/bulk_change_becs"
  ),
  [Route.ImportBulkMandatesBulkChangeBecsNz]: compile(
    "/imports/create/mandates/bulk_change_becs_nz"
  ),
  [Route.ImportBulkMandatesBulkChangeBetalingsservice]: compile(
    "/imports/create/mandates/bulk_change_betalingsservice"
  ),
  [Route.ImportBulkMandatesBulkChangePad]: compile(
    "/imports/create/mandates/bulk_change_pad"
  ),
  [Route.ImportBulkMandatesBulkChangeSepa]: compile(
    "/imports/create/mandates/bulk_change_sepa"
  ),
  [Route.ImportBulkMandatesMandateMigrationPayTo]: compile(
    "/imports/create/mandates/mandate_migration_pay_to"
  ),
  [Route.ImportBulkPayments]: compile("/imports/create/payments"),
  [Route.Import]: compile("/imports/:id"),
  [Route.Imports]: compile("/imports"),
  [Route.ImportsPreview]: compile("/imports-preview"),
  [Route.ImportsBulkChangeEntry]: compile("/imports/bulk-change"),
  [Route.ImportsBulkChangeFlow]: compile("/imports/bulk-change/:scheme"),
  [Route.ImportsBulkChangeRequest]: compile("/imports/bulk-change/request"),
  [Route.InstalmentSchedule]: compile("/instalment-schedules/:id"),
  [Route.InstalmentScheduleCreate]: compile("/instalment-schedules/create"),
  [Route.InstalmentScheduleCreateSuccess]: compile(
    "/instalment-schedules/create-success"
  ),
  [Route.InstalmentScheduleCustomerSearch]: compile(
    "/instalment-schedules/customer-search"
  ),
  [Route.InstalmentScheduleSelection]: compile(
    "instalment-schedules/selection"
  ),
  [Route.InstalmentSchedules]: compile("/instalment-schedules"),
  [Route.IntelligentRetries]: compile("/success-plus/intelligent-retries"),
  [Route.Intro]: compile("/intro"),
  [Route.LogoUpload]: compile("/setup-payments/logo-upload"),
  [Route.Mandate]: compile("/mandates/:id"),
  [Route.Mandates]: compile("/mandates"),
  [Route.MFASetup]: compile("/mfa-setup"),
  [Route.MFASetupAuthenticator]: compile("/mfa-setup/authenticator"),
  [Route.MFASetupSMS]: compile("/mfa-setup/sms"),
  [Route.Monitoring]: compile("/protect-plus/monitoring"),
  [Route.MoreBusinessDetails]: compile("/setup/more-business-details"),
  [Route.NotFound]: compile("/404"),
  [Route.Onboarding]: compile("/onboarding/:step?"),
  [Route.OnboardingPackageSelection]: compile("/onboarding/package-selection"),
  [Route.OnboardingWelcome]: compile("/onboarding/welcome"),
  [Route.OnboardingWelcomeConnect]: compile("/onboarding/welcome-connect"),
  [Route.OneOffPaymentCreate]: compile("/one-off-payment/create"),
  [Route.OneOffPaymentCreateSuccess]: compile(
    "/one-off-payment/create-success"
  ),
  [Route.OneOffPaymentCustomerSearch]: compile(
    "/one-off-payment/customer-search"
  ),
  [Route.OneOffPaymentPaylinksList]: compile("/one-off-payment/paylinks-list"),
  [Route.OneOffPaymentSelection]: compile("/one-off-payment/selection"),
  [Route.PackageSelection]: compile("/setup/package-selection"),
  [Route.PackageSelectionEdit]: compile("/package-selection/edit"),
  [Route.PasswordEdit]: compile("/password/edit"),
  [Route.PaylinksCreate]: compile("/payments/paylinks/create"),
  [Route.PaylinkShare]: compile("/payments/paylinks/:id/share"),
  [Route.Payment]: compile("/payments/:id"),
  [Route.PaymentCreate]: compile("/setup-payments/create"),
  [Route.PaymentName]: compile("/setup-payments/payment-name"),
  [Route.PaymentRequest]: compile("/payment-request/form"),
  [Route.PaymentRequestEmail]: compile("/payment-request/:id/email"),
  [Route.PaymentRequestLink]: compile("/payment-request/:id/link"),
  [Route.Payments]: compile("/payments"),
  [Route.PaymentSetting]: compile("/setup-payments/setting"),
  [Route.PaymentShare]: compile("/setup-payments/share"),
  [Route.PaymentProviderBranding]: compile("/embed/branding/:creditorId"),
  [Route.PaymentVolumes]: compile("/setup/payment-volumes"),
  [Route.Payout]: compile("/payouts/:id"),
  [Route.Payouts]: compile("/payouts"),
  [Route.PayoutTransactions]: compile("/payouts/:id/transactions"),
  [Route.PersonActingOnBehalfOf]: compile("/setup/person-acting-on-behalf-of"),
  [Route.PublicKey]: compile("/developers/public-key"),
  [Route.RecurringPaymentCreate]: compile("/recurring-payment/create"),
  [Route.RecurringPaymentCreateSuccess]: compile(
    "/recurring-payment/create-success"
  ),
  [Route.RecurringPaymentCustomerSearch]: compile(
    "/recurring-payment/customer-search"
  ),
  [Route.RecurringPaymentSelection]: compile("/recurring-payment/selection"),
  [Route.Refer]: compile("/refer"),
  [Route.ReferralLink]: compile("/refer/:geo/:name/:code"),
  [Route.Refund]: compile("/refunds/:id"),
  [Route.Refunds]: compile("/refunds"),
  [Route.RequestPayments]: compile("/request-payments"),
  [Route.RiskSettings]: compile("/protect-plus/risk-settings"),
  [Route.RiskSettingsChange]: compile("/protect-plus/risk-settings/edit"),
  [Route.RouteTo]: compile("/routeto"),
  [Route.Search]: compile("/search"),
  [Route.Settings]: compile("/settings"),
  [Route.Setup]: compile("/setup"),
  [Route.SetupPayments]: compile("/setup-payments"),
  [Route.SetupUser]: compile("/setup-user"),
  [Route.SetupUserVerify]: compile("/setup-user/verify"),
  [Route.SignIn]: compile("/sign-in"),
  [Route.SignUp]: compile("/sign-up"),
  [Route.SignUpExp]: compile("/sign-up-exp"),
  [Route.Subscription]: compile("/subscriptions/:id"),
  [Route.SubscriptionPause]: compile("/subscriptions/:id/pause"),
  [Route.SubscriptionResume]: compile("/subscriptions/:id/resume"),
  [Route.Subscriptions]: compile("/subscriptions"),
  [Route.SubscriptionTemplate]: compile("/subscription-templates/:id"),
  [Route.SubscriptionTemplateCustomers]: compile(
    "/subscription-templates/:id/customers"
  ),
  [Route.SubscriptionTemplateEdit]: compile("/subscription-templates/:id/edit"),
  [Route.SubscriptionTemplates]: compile("/subscription-templates"),
  [Route.SubscriptionTemplatesCreate]: compile(
    "/subscription-templates/create"
  ),
  [Route.SubscriptionTemplatesCustomerSearch]: compile(
    "/subscription-templates/customer-search"
  ),
  [Route.SubscriptionTemplateShare]: compile(
    "/subscription-templates/:id/customers/create"
  ),
  [Route.SubscriptionTemplateShareAssignCustomers]: compile(
    "/subscription-templates/:id/customers/create/assign-customers"
  ),
  [Route.SubscriptionTemplatesSelection]: compile(
    "subscription-templates/selection"
  ),
  [Route.SubscriptionTemplateManagePaymentSchedule]: compile(
    "/subscription-templates/:id/manage-payment-schedule"
  ),
  [Route.SubscriptionTemplateSchedulePause]: compile(
    "/subscription-templates/:id/manage-payment-schedule/schedule-pause"
  ),
  [Route.SubscriptionTemplateScheduleResume]: compile(
    "/subscription-templates/:planId/manage-payment-schedule/:planSchedulerId/schedule-resume"
  ),
  [Route.SuccessPlus]: compile("/success-plus"),
  [Route.SuccessPlusConfigure]: compile("/success-plus/configure"),
  [Route.SuccessPlusReporting]: compile("/success-plus/reporting"),
  [Route.Team]: compile("/company/team"),
  [Route.TeamUserEdit]: compile("/company/team/users/:id/edit"),
  [Route.TrusteeDetails]: compile("/setup/trustee-details"),
  [Route.TrustOwners]: compile("/setup/trust-owners"),
  [Route.VerificationBanner]: compile("/verification-banner"),
  [Route.Verified]: compile("/setup/verified"),
  [Route.VerifyBank]: compile("/setup/verify-bank"),
  [Route.VerifyBankDocument]: compile("/setup/verify-bank-document"),
  [Route.Welcome]: compile("/welcome"),
  [Route.WelcomePaymentType]: compile("/welcome/payment-type"),
  [Route.WelcomeUsageSelection]: compile("/welcome/usage-selection"),
  [Route.ZendeskFormsInterceptor]: compile("/forms/zendesk/:formId"),
};

/**
 * External routes for the application that point to enterprise-dashboard. We do
 * a hard refresh when navigating to these pages in order to hit the load
 * balancer and load the enterprise-dashboard.
 */
const externalRoutes: ReadonlySet<Route> = new Set([
  Route.Admin,
  Route.CreditorBankAccounts,
  Route.CreditorDetails,
  Route.Creditors,
  Route.Developers,
  Route.DevelopersAccessTokenEdit,
  Route.DevelopersPartnersFees,
  Route.ImportBulkPayments,
  Route.Imports,
  Route.ImportBulkCustomers,
  Route.ImportBulkMandatesBulkChangeAch,
  Route.ImportBulkMandatesBulkChangeAutogiro,
  Route.ImportBulkMandatesBulkChangeBacs,
  Route.ImportBulkMandatesBulkChangeBecs,
  Route.ImportBulkMandatesBulkChangeBecsNz,
  Route.ImportBulkMandatesBulkChangeBetalingsservice,
  Route.ImportBulkMandatesBulkChangePad,
  Route.ImportBulkMandatesBulkChangeSepa,
  Route.ImportBulkMandatesMandateMigrationPayTo,
  Route.Onboarding,
  Route.OnboardingPackageSelection,
  Route.Refund,
  Route.Refunds,
  Route.Welcome,
  Route.WelcomePaymentType,
  Route.WelcomeUsageSelection,
]);

/** An array of internal routes that can only be accessed when not logged in. */
export const PublicOnlyRoutes: string[] = [
  routes[Route.SignIn](),
  routes[Route.SignUp](),
  routes[Route.SignUpExp](),
  routes[Route.ForgotPassword](),
  routes[Route.ForcedPasswordReset](),
  routes[Route.PasswordEdit](),
  routes[Route.CreateAccount](),
  routes[Route.CreatePaymentProviderAccount](),
];

/** An array of dynamic internal routes that can only be accessed when not logged in. */
export const PublicOnlyDynamicRoutes: string[] = ["/refer/:geo/:name/:code"];

export const PublicOnlyRoutesWithoutSegment: string[] = [
  routes[Route.SignIn](),
  routes[Route.ForgotPassword](),
  routes[Route.PasswordEdit](),
];

export const MFASetupRoutes: string[] = [
  routes[Route.MFASetup](),
  routes[Route.MFASetupSMS](),
  routes[Route.MFASetupAuthenticator](),
];

/**
 * HOW TO ADD PAGES TO FULLSTORY
 * Follow the steps in this page:
 * https://gocardless.atlassian.net/wiki/spaces/Spark/pages/6469622611/Fullstory+how-tos#How-can-I-add-Fullstory-on-a-new-page%3F
 * Then add your route to the array below.
 */
export const FullStoryAllowedRoutes: string[] = [
  routes[Route.Intro](),
  routes[Route.SetupPayments](),
  routes[Route.LogoUpload](),
  routes[Route.PaymentCreate](),
  routes[Route.PaymentName](),
  routes[Route.PaymentSetting](),
  routes[Route.PaymentShare](),
  routes[Route.BankDetailsSelect](),
  routes[Route.AccountStatus](),
  // routes[Route.SignUp](),
];

/**
 * Additional routes recorded by FullStory when KCC experiment is enabled
 */
export const KccFullStoryAllowedRoutes: string[] = [routes[Route.Home]()];

export type RouteParams = Record<string, string | undefined>;

export const getRoute = (route: Route, params?: RouteParams) =>
  routes[route](params);

export const isExternal = (route: Route) => externalRoutes.has(route);

export const matchesDynamicRoute = (
  pathname: string,
  dynamicRoutes: string[]
): boolean => dynamicRoutes.some((route) => pathToRegexp(route).test(pathname));
