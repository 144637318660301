import { ReactNode } from "react";
import { HTTPError } from "@gocardless/api/utils/api";
import {
  getErrorsFromErrorResponse,
  Error as ParsedHttpError,
  Errors,
} from "@gocardless/api/utils/error";

import { NotificationPayload } from "./useToastNotification";

export const RATE_LIMIT_EXCEEDED_MSG = "Rate limit exceeded";
export const PASSWORD_INCORRECT_MSG = "Current password is incorrect";
export const OTP_INVALID_REASONS = [
  "two_factor_auth_expired_otp_sms_code",
  "two_factor_auth_expired_otp_totp_code",
  "two_factor_auth_invalid_otp_sms_code",
  "two_factor_auth_invalid_otp_totp_code",
];

interface ErrorMatcher {
  match: string;
  content: {
    message: ReactNode;
    title?: ReactNode;
  };
}

/*
  If you want to listen out for a specific error and display a custom content in an
  error notification you can pass a ErrorMatcher object as an argument to the handler.
*/

export const errorNotificationHandler = async (
  err: HTTPError,
  notify: (payload: NotificationPayload) => void,
  matcher?: ErrorMatcher
) => {
  const errors = await getErrorsFromErrorResponse(err);
  errorsNotificationHandler(errors, notify, matcher);
};

export const errorsNotificationHandler = async (
  errors: Errors | Error[],
  notify: (payload: NotificationPayload) => void,
  matcher?: ErrorMatcher
) => {
  if (errors.length > 0) {
    (errors as Errors).forEach(({ message }) => {
      if (message && matcher && message === matcher.match) {
        notify({ ...matcher.content });
      } else if (message) {
        notify({ message });
      }
    });
  }
};

export const passwordInvalidErrorHandler = async (
  err: HTTPError,
  onMatch: () => void,
  notify: (payload: NotificationPayload) => void
) => {
  const errors = await getErrorsFromErrorResponse(err);
  if (errors.length > 0) {
    errors.forEach(({ message }) => {
      if (message === PASSWORD_INCORRECT_MSG) {
        onMatch();
      } else {
        notify({
          message,
        });
      }
    });
  }
};

/**
 * Type represents an error handler function to handle http/api errors.
 * It should return true if the handler handled the error successfully,
 * else it should return false.
 *
 */
export type ErrorHandler = (err: ParsedHttpError) => boolean;

/**
 * A helper function to handle an API HTTPError.
 * If the handler does not handle the error, the fallback handler is called.
 *
 * @param err HTTP Error to be handled
 * @param handler Error handler function
 * @param fallback fallback handler if handler does not handle request
 */
export const handleHttpError = async (
  err: HTTPError,
  handler: ErrorHandler,
  fallback: ErrorHandler
): Promise<void> => {
  const errors = await getErrorsFromErrorResponse(err);
  errors.forEach((localErr) => {
    if (!handler(localErr)) {
      fallback(localErr);
    }
  });
};

/**
 * This utility function takes a variable number of error handlers as argument
 * and returns a new ErrorHandler that calls each of the argument
 *
 * It is useful to be used with the `handleHttpError` function to compose a
 * number of handlers.
 *
 * @example
 * // The below example would invoke both the `handleInvalidPassword`
 * // and `handleInvalidOtp` error handlers and if they both return false,
 * // it would then call call the `handleGeneralError` handler.
 *
 * handleHttpError(
 *  err,
 *  composeErrorHandlers(
 *    handleInvalidOtp,
 *    handleInvalidPassword,
 *  ),
 *  handleGeneralError
 * );
 *
 *
 * @param handlers list of error handlers to compose
 * @returns an ErrorHandler
 */
export const composeErrorHandlers =
  (...handlers: ErrorHandler[]): ErrorHandler =>
  (err: ParsedHttpError): boolean =>
    handlers.map((handler) => handler(err)).some((result) => result === true);

export const makeOtpErrorHandler =
  (callback: () => void) =>
  ({ reason }: ParsedHttpError) => {
    if (!OTP_INVALID_REASONS.includes(reason)) {
      return false;
    }

    callback();
    return true;
  };

export const makePasswordErrorHandler =
  (callback: () => void) =>
  ({ message }: ParsedHttpError) => {
    if (message !== PASSWORD_INCORRECT_MSG) {
      return false;
    }

    callback();
    return true;
  };

export const makeNotificationErrorHandler =
  (callback: (payload: NotificationPayload) => void) =>
  ({ message }: ParsedHttpError) => {
    callback({ message });
    return true;
  };
