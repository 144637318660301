export enum APILocale {
  DA_DK = "da-DK",
  DE_DE = "de-DE",
  EN_GB = "en-GB",
  ES_ES = "es-ES",
  FR_FR = "fr-FR",
  IT_IT = "it-IT",
  NB_NO = "nb-NO",
  NL_NL = "nl-NL",
  PT_PT = "pt-PT",
  SL_SI = "sl-SI",
  SV_SE = "sv-SE",
}

export enum UserLocale {
  DE_DE = "de-DE",
  EN_GB = "en-GB",
  ES_ES = "es-ES",
  FR_FR = "fr-FR",
}

export enum LanguageCode {
  DA = "da",
  DE = "de",
  EN = "en",
  ES = "es",
  FR = "fr",
  IT = "it",
  NL = "nl",
  NB = "nb",
  PT = "pt",
  SL = "sl",
  SV = "sv",
}
export const LanguageNameToCode: Readonly<Record<string, LanguageCode>> = {
  da: LanguageCode.DA,
  de: LanguageCode.DE,
  en: LanguageCode.EN,
  fr: LanguageCode.FR,
  it: LanguageCode.IT,
  nl: LanguageCode.NL,
  nb: LanguageCode.NB,
  pt: LanguageCode.PT,
  sl: LanguageCode.SL,
  sv: LanguageCode.SV,
};

export const Languages: Record<LanguageCode, string> = {
  [LanguageCode.DA]: "Danish",
  [LanguageCode.DE]: "German",
  [LanguageCode.EN]: "English",
  [LanguageCode.ES]: "Spanish",
  [LanguageCode.FR]: "French",
  [LanguageCode.IT]: "Italian",
  [LanguageCode.NL]: "Dutch",
  [LanguageCode.NB]: "Norwegian",
  [LanguageCode.PT]: "Portuguese",
  [LanguageCode.SL]: "Slovenian",
  [LanguageCode.SV]: "Swedish",
};

export const ShorthandLocale: Record<UserLocale, string> = {
  [UserLocale.EN_GB]: "en",
  [UserLocale.DE_DE]: "de",
  [UserLocale.FR_FR]: "fr",
  [UserLocale.ES_ES]: "es",
};

export const DEFAULT_LOCALE = UserLocale.EN_GB;

export const LocaleNames: Record<APILocale | UserLocale, string> = {
  [APILocale.DA_DK]: "Dansk (Danmark)",
  [APILocale.DE_DE]: "Deutsch (Deutschland)",
  [APILocale.EN_GB]: "English (United Kingdom)",
  [APILocale.ES_ES]: "Español (España)",
  [APILocale.FR_FR]: "Français (France)",
  [APILocale.IT_IT]: "Italiano (Italia)",
  [APILocale.NB_NO]: "Norsk (Norge)",
  [APILocale.NL_NL]: "Nederlands (Nederland)",
  [APILocale.PT_PT]: "Português (Portugal)",
  [APILocale.SL_SI]: "Slovenščina (Slovenija)",
  [APILocale.SV_SE]: "Svenska (Sverige)",
};
