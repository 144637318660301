import { useContext, useEffect } from "react";
import _ from "lodash";
import { ExportResource, ExportsStatus } from "@gocardless/api/dashboard/types";
import { useUserShowSelf } from "@gocardless/api/dashboard/user";
import { useExportsLocalStorage } from "src/common/hooks/useExportsLocalStorage";
import { GlobalState } from "src/components/global-state/GlobalStateProvider";
import { showDrift } from "src/components/third-parties/Drift/Drift";

import ExportsCentre from "./ExportsCentre";

export type onExportUpdateFunc = (e: ExportResource) => void;

export interface ExportsLocalStorageCache {
  completedExports: string[];
  failedExports: string[];
  pendingExports: string[];
  isCentreOpen: boolean;
  isShowingCentre: boolean;
}

export const defaultExport: ExportsLocalStorageCache = {
  pendingExports: [],
  failedExports: [],
  completedExports: [],
  isCentreOpen: true,
  isShowingCentre: false,
};

// Update localstorage and state when export status is updated
const onExportUpdate = (
  exports: ExportsLocalStorageCache,
  exportResource: ExportResource,
  onUpdateComplete?: (e: ExportsLocalStorageCache) => void
) => {
  if (!exports) {
    return;
  }

  if (exportResource?.id) {
    switch (exportResource?.status) {
      case ExportsStatus.Pending:
        exports.pendingExports = _.union(
          [exportResource?.id],
          exports.pendingExports
        );
        break;
      case ExportsStatus.Processing:
        exports.pendingExports = _.union(
          [exportResource?.id],
          exports.pendingExports
        );
        break;
      case ExportsStatus.Successful:
        exports.completedExports = _.union(
          [exportResource?.id],
          exports.completedExports
        );
        exports.pendingExports = _.without(
          exports.pendingExports,
          exportResource?.id
        );
        break;
      case ExportsStatus.Unsuccessful:
        exports.failedExports = _.union(
          exportResource?.id,
          exports.failedExports
        );
        exports.pendingExports = _.without(
          exports.pendingExports,
          exportResource?.id
        );
        break;
    }
  }
  if (onUpdateComplete) onUpdateComplete(exports);
};

export const ExportsCentreConnect: React.FC = () => {
  const { data: user } = useUserShowSelf();
  const userId = user?.users?.id;

  const { exports, setExports } = useContext(GlobalState);

  const [cachedExports] = useExportsLocalStorage();

  useEffect(() => {
    let initialIsShowingCentre = false;
    if (cachedExports) {
      initialIsShowingCentre =
        cachedExports.pendingExports.length > 0 ||
        cachedExports.completedExports.length > 0 ||
        cachedExports.failedExports.length > 0;
    }

    setExports({
      ...cachedExports,
      isShowingCentre: initialIsShowingCentre,
    });
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!exports) {
    return <></>;
  }

  const persistExports = (exportsCache: ExportsLocalStorageCache) => {
    setExports(exportsCache);
  };

  const onToggle = () => {
    const exps = { ...exports };
    exps.isCentreOpen = !exps.isCentreOpen;
    persistExports(exps);
  };

  return (
    <ExportsCentre
      completedExports={exports.completedExports}
      failedExports={exports.failedExports}
      dismiss={() => {
        persistExports(defaultExport);
        showDrift();
      }}
      isShowingCentre={exports.isShowingCentre}
      isCentreOpen={exports.isCentreOpen}
      isUserLoggedIn={!!userId}
      pendingExports={exports.pendingExports}
      toggle={onToggle}
      onExportUpdate={(e) =>
        onExportUpdate(exports, e, (exps) => {
          persistExports(exps);
        })
      }
    />
  );
};
